.sparkleClusterSketch {
  width: 100px;
  height: 100px;
  top: -60px;
  left: -25px;
  rotate: -5deg;
  transform: rotateY(180deg);
}

@media (max-width: 700px) {
  .sparkleClusterSketch {
    width: 70px;
    height: 70px;
  }
}
