import React, { useState, useEffect } from "react";
import { className } from "../../../utilities/helpers";
import * as style from "./SketchDecoration.module.css";

function SketchDecoration({ sketchStyle, rotating = false }) {
  const [rotateAmount, setRotateAmount] = useState(0);
  const rotateOnScroll = () => {
    setRotateAmount(window.scrollY / 2);
  };

  useEffect(() => {
    window.addEventListener("scroll", rotateOnScroll);

    return () => {
      window.removeEventListener("scroll", rotateOnScroll);
    };
  }, []);

  return (
    <div
      {...className(style.sketch, sketchStyle)}
      style={rotating ? { transform: `rotate(-${rotateAmount}deg)` } : {}}
    ></div>
  );
}

export default SketchDecoration;
