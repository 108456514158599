.pageWrapper {
  background-image: url("../../../assets/images/background-page.svg");
  background-repeat: repeat;
  background-color: var(--primary-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: hidden;
}
