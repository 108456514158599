.projectCardContainer {
  width: 80%;
  min-width: 500px;
  max-width: var(--content-max-width);
  display: flex;
  min-height: 320px;
  background-color: var(--primary-light);
  box-shadow: 0 0 10px var(--text-bg);
  border-radius: var(--border-radius);
  position: relative;
}

.projectName {
  width: 50px;
  padding: var(--padding-xs);
  background-color: var(--primary-dark);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  color: var(--primary-light);
  transform: rotate(-90deg);
  letter-spacing: 10px;
  text-indent: 0.5em;
}

.projectBody {
  width: 100%;
  background-color: var(--primary-light);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  border-radius: var(--border-radius);
}

.projectDescription {
  width: 60%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: var(--padding-sm);
  padding-left: var(--padding-md);
  gap: var(--gap-sm);
}

.projectImage {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40%;
  background-position: center;
  background-size: cover;
  display: flex;
  clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
  transition: all 0.5s ease-in-out;
  opacity: 0.8;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.buttonContainer {
  position: absolute;
  inset: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 0;
}

.button {
  border: 1px solid var(--primary-dark);
  background-color: var(--primary-light);
  color: var(--primary-dark);
  opacity: 0;
}

.iconContainer {
  right: 5;
  bottom: 5;
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: var(--primary-dark);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.openInNewTabIcon {
  height: 30px;
  width: 30px;
  color: var(--primary-light);
}

@keyframes btnReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (hover: hover) {
  .projectCardContainer:hover {
    cursor: pointer;
  }

  .projectCardContainer:hover > .buttonContainer {
    height: 100%;
  }

  .projectCardContainer:hover .button {
    animation-name: btnReveal;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    box-shadow: none !important;
  }

  .projectCardContainer:hover .projectImage {
    width: 85%;
    opacity: 1;
  }

  .projectCardContainer:hover .projectDescription {
    opacity: 0.2;
  }
}

@media (max-width: 700px) {
  .projectCardContainer {
    width: 90%;
    min-width: 265px;
    max-width: 300px;
    flex-direction: column;
    min-height: 600px;
  }

  .projectName {
    width: 100%;
    border-top-right-radius: var(--border-radius);
    border-bottom-left-radius: 0;
    height: 50px;
  }

  .name {
    transform: rotate(0deg);
  }

  .projectBody {
    height: 100%;
    flex-grow: 1;
  }

  .projectDescription {
    width: 100%;
    height: fit-content;
    max-height: 70%;
    padding: var(--padding-md);
  }

  .projectImage {
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 70%;
    clip-path: polygon(0% 25%, 100% 0%, 100% 100%, 0% 100%);
    transition: all 0.5s ease-in-out;
    opacity: 0.8;
    border-top-right-radius: 0;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  @media (hover: hover) {
    .projectCardContainer:hover .projectImage {
      width: 100%;
      top: 20%;
    }
  }
}

@media (max-width: 480px) {
  .projectCardContainer {
    min-height: 620px;
  }
}
