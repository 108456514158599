.sectionWrapper {
  padding: var(--padding-md) var(--padding-lg);
  margin: var(--margin-lg) 0;
  min-height: 50vh;
  width: 100%;
  max-width: var(--container-max-width);
}

@media (max-width: 480px) {
  .sectionWrapper {
    padding: var(--padding-md);
    margin: var(--margin-lg) 0;
    padding-top: var(--padding-lg);
  }
}
