.titleText {
  font-family: var(--font-primary);
  font-weight: 400;
  text-transform: uppercase;
  color: var(--primary-dark);
  font-size: 12vw;
  margin: var(--margin-md) 0;
}

.heroTitleText {
  font-family: var(--font-primary);
  font-weight: 400;
  text-transform: uppercase;
  color: var(--primary-light);
  font-size: 12vw;
  margin: var(--margin-md) 0;
}

.heroTitleText::selection {
  color: var(--primary-dark);
  background: var(--primary-light);
}

.heroNormalText {
  font-family: var(--font-secondary);
  font-weight: 400;
  color: var(--primary-light);
  font-size: 1.5rem;
  line-height: 35px;
}

.heroNormalText::selection {
  color: var(--primary-dark);
  background: var(--primary-light);
}

.normalText {
  font-family: var(--font-secondary);
  font-weight: 400;
  color: var(--primary-dark);
  font-size: 1.2rem;
  line-height: 25px;
}

.smallText {
  font-family: var(--font-secondary);
  font-weight: 400;
  color: var(--primary-dark);
  font-size: 1rem;
}

.subtitleText {
  font-family: var(--font-primary);
  font-weight: 400;
  text-transform: uppercase;
  color: var(--primary-dark);
  font-size: 2rem;
  margin-bottom: var(--margin-sm);
}

.sectionText {
  width: 90%;
  max-width: var(--content-max-width);
  display: block;
}

.primaryLink {
  font-family: var(--font-secondary);
  font-weight: 400;
  text-transform: uppercase;
  color: var(--primary-light);
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.tagText {
  font-family: var(--font-secondary);
  font-weight: 400;
  color: var(--primary-dark);
  font-size: 1.3rem;
}

.primaryButtonText {
  font-family: var(--font-primary);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.headerInitialsText {
  font-family: var(--font-primary);
  font-weight: 400;
  color: var(--primary-light);
  font-size: 1.2rem;
}

.inputLabelText {
  font-family: var(--font-secondary);
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--primary-dark);
  text-transform: uppercase;
}

.inputText {
  font-family: var(--font-secondary);
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--primary-dark);
}

.footerText {
  font-family: var(--font-secondary);
  font-weight: 400;
  color: var(--primary-light);
  text-align: center;
  font-size: 1rem;
}

.footerText::selection {
  color: var(--primary-dark);
  background: var(--primary-light);
}

@media (hover: hover) {
  .primaryLink:hover {
    font-style: italic;
  }
}

@media (min-width: 1100px) {
  .heroTitleText {
    font-size: 9rem;
  }

  .titleText {
    font-size: 9rem;
  }
}

@media (max-width: 480px) {
  .heroTitleText {
    font-size: 17vw;
  }

  .titleText {
    font-size: 3rem;
  }

  .subtitleText {
    font-size: 1.8rem;
  }

  .heroNormalText {
    font-size: 1.2rem;
  }

  .normalText {
    font-size: 1rem;
  }
  .sectionText {
    width: 95%;
  }
  .primaryButtonText {
    font-size: 1.2rem;
  }
}
