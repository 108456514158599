import projectImgMortle from "../assets/images/project-cards/mortle.png";
import projectImgMemery from "../assets/images/project-cards/memery.png";
import projectImgNotelux from "../assets/images/project-cards/notelux.png";
import LinkedInIcon from "../assets/icons/LinkedInIcon";
import EnvelopeIcon from "../assets/icons/EnvelopeIcon";
import GithubIcon from "../assets/icons/GithubIcon";

export const githubLink = "https://github.com/anna-rudas";
export const emailAddress = "mailto:mail@annarudas.com";
export const linkedInLink = "https://www.linkedin.com/in/annarudas/";
export const projectLinkMemery = "https://memery.annarudas.com/";
export const projectLinkNotelux = "https://notelux.annarudas.com/";
export const projectLinkMortle = "https://mortle.annarudas.com/";
export const githubLinkMemery = "https://github.com/anna-rudas/memery";
export const githubLinkNotelux = "https://github.com/anna-rudas/notelux";
export const githubLinkMortle = "https://github.com/anna-rudas/wordle";

export const mainPageNavigationLinks = ["about", "projects", "contact"];
export const projectsPageNavigationLinks = ["notelux", "memery", "mortle"];

export const footerLinks = [
  {
    icon: <LinkedInIcon />,
    link: linkedInLink,
  },
  {
    icon: <EnvelopeIcon />,
    link: emailAddress,
  },
  {
    icon: <GithubIcon />,
    link: githubLink,
  },
];

export const skillTags = [
  "HTML",
  "CSS",
  "Sass",
  "JavaScript",
  "TypeScript",
  "React",
  "Git",
  "VueJs",
  "Figma",
  "Firebase",
];

export const projectSummaries = [
  {
    name: "notelux",
    title: "notes app",
    intro:
      "A note-taking application with extensive features and responsive UI.",
    featureTags: [
      "User Authentication",
      "Personalization",
      "Collaboration",
      "Anonymous Users",
    ],
    projectImg: projectImgNotelux,
    link: projectLinkNotelux,
  },
  {
    name: "memery",
    title: "memory game",
    intro:
      "A memory game with card packs of varying sizes and types. You can track your progress and how many turns it took to complete the game.",
    featureTags: ["3D Cards", "Pick your Pack", "Pick your Board Size"],
    projectImg: projectImgMemery,
    link: projectLinkMemery,
  },
  {
    name: "mortle",
    title: "wordle game",
    intro:
      "The classic wordle game with added dictionary definitions and saved statistics data.",
    featureTags: [
      "CSS Animations",
      "Unlimited Words",
      "Scoreboard",
      "Dictionary Definitions",
    ],
    projectImg: projectImgMortle,
    link: projectLinkMortle,
  },
];
