.frame {
  border-radius: var(--border-radius);
  border: 1px solid var(--primary-dark);
}

.frameRight {
  border-top-right-radius: var(--border-radius-accent);
  box-shadow: -10px 10px 0px var(--primary-dark);
}

.frameLeft {
  border-top-left-radius: var(--border-radius-accent);
  box-shadow: 10px 10px 0px var(--primary-dark);
}

.sketchHelper {
  position: relative;
}

.sketchAnimation {
  animation-duration: var(--sketch-animation-duration);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
