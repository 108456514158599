.link {
  color: var(--primary-dark);
  transition: all 0.3s ease-in-out;
  text-decoration: underline;
}

@media (hover: hover) {
  .link:hover {
    text-decoration-line: underline;
    text-decoration-style: double;
  }

  .link:focus-visible {
    text-decoration-line: underline;
    text-decoration-style: double;
  }
}
