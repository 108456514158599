import React from "react";
import * as style from "./HeroProjects.module.css";
import HeroWrapper from "../../templates/HeroWrapper/HeroWrapper";
import PrimaryButton from "../../buttons/PrimaryButton/PrimaryButton";
import SketchDecoration from "../../templates/SketchDecoration/SketchDecoration";
import AnimatedSparkleClusterSketch from "../../features/AnimatedSparkleClusterSketch";

function HeroProjects() {
  return (
    <HeroWrapper
      title="My Projects"
      textAfterTitle="To see what I'm currently working on, take a look around on my Github."
      arrowIconLink="#memery"
      heroTitleSketch={
        <AnimatedSparkleClusterSketch
          extraSparkleClusterStyle={style.sparkleClusterSketch}
        />
      }
    >
      <PrimaryButton
        buttonLinkInternal="/"
        isDarkButtonStyle={false}
        onButtonClick={() => {
          window.scrollTo(0, 0);
        }}
        primaryButtonSketch={
          <>
            <SketchDecoration
              sketchStyle={style.rotatingStarSketchMedium}
              rotating={true}
            />
            <SketchDecoration
              sketchStyle={style.rotatingStarSketchSmall}
              rotating={true}
            />
          </>
        }
      >
        Back to home page
      </PrimaryButton>
    </HeroWrapper>
  );
}

export default HeroProjects;
