import React from "react";
import SketchDecoration from "../../templates/SketchDecoration";
import * as style from "./AnimatedSparkleClusterSketch.module.css";
import * as shared from "../../../assets/styles/shared.module.css";

function AnimatedSparkleClusterSketch({ extraSparkleClusterStyle }) {
  return (
    <SketchDecoration
      sketchStyle={`${style.animatedSparkleCluster} ${shared.sketchAnimation} ${extraSparkleClusterStyle}`}
    />
  );
}

export default AnimatedSparkleClusterSketch;
