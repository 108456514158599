import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/MainPage";
import Projects from "./pages/ProjectsPage";
import PageNotFound from "./pages/PageNotFound";
import FontFaceObserver from "fontfaceobserver";

const primaryFontObserver = new FontFaceObserver("Abril Fatface");
const secondaryFontObserver = new FontFaceObserver("Montserrat");

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

Promise.all([primaryFontObserver.load(), secondaryFontObserver.load()]).then(
  () => {
    createRoot(document.getElementById("root")).render(<App />);
  }
);
