import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M5 2h2a1 1 0 01.117 1.993L7 4H5a1 1 0 00-.993.883L4 5v14a1 1 0 00.883.993L5 20h14a1 1 0 00.993-.883L20 19v-2a1 1 0 011.993-.117L22 17v2a3 3 0 01-2.824 2.995L19 22H5a3 3 0 01-2.995-2.824L2 19V5a3 3 0 012.824-2.995L5 2h2-2zm16 0l.081.003.12.017.111.03.111.044.098.052.096.067.09.08.097.112.071.11.031.062.034.081.024.076.03.148L22 3v6a1 1 0 01-2 0V5.414l-6.293 6.293a1 1 0 01-1.32.083l-.094-.083a1 1 0 01-.083-1.32l.083-.094L18.584 4H15a1 1 0 010-2h6z"
      ></path>
    </svg>
  );
}

export default Icon;
