import React from "react";
import ProjectWrapper from "../../templates/ProjectWrapper/ProjectWrapper";
import desktopScreenshot1 from "../../../assets/images/projects/mortle-desktop-1.png";
import desktopScreenshot2 from "../../../assets/images/projects/mortle-desktop-2.png";
import desktopScreenshot3 from "../../../assets/images/projects/mortle-desktop-3.png";
import mobileScreenshot from "../../../assets/images/projects/mortle-mobile.png";
import { projectLinkMortle, githubLinkMortle } from "../../../data/constants";
import * as style from "./ProjectMortle.module.css";
import AnimatedSparkleClusterSketch from "../../features/AnimatedSparkleClusterSketch";

function ProjectMortle() {
  const desktopScreenshots = new Array(
    desktopScreenshot1,
    desktopScreenshot2,
    desktopScreenshot3
  );

  return (
    <ProjectWrapper
      projectName="mortle"
      mobileScreenshot={mobileScreenshot}
      desktopScreenshots={desktopScreenshots}
      githubLink={githubLinkMortle}
      websiteLink={projectLinkMortle}
      projectTitleSketch={
        <AnimatedSparkleClusterSketch
          extraSparkleClusterStyle={style.sparkleClusterSketch}
        />
      }
    >
      <>
        I absolutely love word games, and the classic Wordle is one of my
        favourites! One thing I was always missing from it though is definitions
        for the solutions, because sometimes I have no idea what they mean. So,
        I added them! I have also not set a word limit per day, so it&apos;s a
        perfect game to pass the time while also working my brain. <br />
        Besides the main gameplay, there is also the functionality for users to
        see their statistics, with information about their win percentage and
        guess distribution.
        <br /> For the solution word generation previously I have relied on a
        random word API, but then decided to switch to storing all words in a
        Firestore database. This gives me freedom to add or delete words, as
        well as better reliability.
        <br />
        What I love the most about this project is the design: it&apos;s unique
        and fun and it gave me a lot of joy to create it.
      </>
    </ProjectWrapper>
  );
}

export default ProjectMortle;
