import React from "react";
import ProjectWrapper from "../../templates/ProjectWrapper/ProjectWrapper";
import desktopScreenshot1 from "../../../assets/images/projects/notelux-desktop-1.png";
import desktopScreenshot2 from "../../../assets/images/projects/notelux-desktop-2.png";
import desktopScreenshot3 from "../../../assets/images/projects/notelux-desktop-3.png";
import mobileScreenshot from "../../../assets/images/projects/notelux-mobile.png";
import { projectLinkNotelux, githubLinkNotelux } from "../../../data/constants";
import * as style from "./ProjectNotelux.module.css";
import SketchDecoration from "../../templates/SketchDecoration";

function ProjectNotelux() {
  const desktopScreenshots = new Array(
    desktopScreenshot1,
    desktopScreenshot2,
    desktopScreenshot3
  );

  return (
    <ProjectWrapper
      projectName="notelux"
      mobileScreenshot={mobileScreenshot}
      desktopScreenshots={desktopScreenshots}
      githubLink={githubLinkNotelux}
      websiteLink={projectLinkNotelux}
      projectMobileScreenshotSketch={
        <>
          <SketchDecoration
            sketchStyle={style.rotatingStarSketchMedium}
            rotating={true}
          />
          <SketchDecoration
            sketchStyle={style.rotatingStarSketchSmall}
            rotating={true}
          />
        </>
      }
    >
      <>
        Notelux is a CRUD application, storing data in Firebase. Users can be
        anonymous and later upgrade or sign up and create an account from the
        start. Notes can be shared with other users for collaborative work, as
        well as customized by color. On the dashboard, users can choose between
        light and dark theme, or between list and grid view. <br />
        It was really fun to design and work on this project, from figuring out
        how to stack and sort the notes, to adding basic application features
        like changing the email address and password.
        <br />
        What I have struggled with the most was writing the database security
        rules; took me awhile to get it right. This project gave me greater
        understanding of database handling and UX design and I am very happy
        with how it turned out.
      </>
    </ProjectWrapper>
  );
}

export default ProjectNotelux;
