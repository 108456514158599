.projectSummariesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--margin-lg);
  gap: var(--gap-lg);
  width: 100%;
}

.rotatingStarSketchLarge {
  width: 100px;
  height: 100px;
  top: -110px;
  right: -35px;
  background-color: var(--sketch-dark);
  -webkit-mask-image: url("../../../assets/sketches/star-3.svg");
  mask-image: url("../../../assets/sketches/star-3.svg");
}

.rotatingStarSketchSmall {
  width: 50px;
  height: 50px;
  top: -20px;
  right: -55px;
  background-color: var(--sketch-dark);
  -webkit-mask-image: url("../../../assets/sketches/star-2.svg");
  mask-image: url("../../../assets/sketches/star-2.svg");
}

.arrowSketch {
  width: 100px;
  height: 100px;
  top: 15px;
  right: -100px;
  background-color: var(--sketch-dark);
  -webkit-mask-image: url("../../../assets/sketches/arrow-1.svg");
  mask-image: url("../../../assets/sketches/arrow-1.svg");
  pointer-events: none;
}

@media (max-width: 480px) {
  .arrowSketch {
    width: 50px;
    height: 50px;
    top: 25px;
    right: -45px;
  }
}
