import React, { useEffect } from "react";
import { className } from "../../../utilities/helpers";
import * as style from "./HeroWrapper.module.css";
import * as textStyles from "../../../assets/styles/text-styles.module.css";
import * as shared from "../../../assets/styles/shared.module.css";
import ArrowIcon from "../../../assets/icons/ArrowIcon";

function HeroWrapper({
  textBeforeTitle,
  title,
  textAfterTitle,
  children,
  arrowIconLink,
  heroTitleSketch,
}) {
  const convertStyle = () => {
    let height = window.innerHeight;
    document.documentElement.style.setProperty("--hero-height", `${height}px`);
  };

  useEffect(() => {
    let width = null;

    const handleResize = () => {
      if (window.innerWidth !== width) {
        width = window.innerWidth;
        convertStyle();
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div {...className(style.heroWrapper)}>
      <div {...className(style.heroContent)}>
        {textBeforeTitle && (
          <span {...className(textStyles.heroNormalText, style.heroAnimation)}>
            {textBeforeTitle}
          </span>
        )}
        <span
          {...className(
            textStyles.heroTitleText,
            shared.sketchHelper,
            style.heroTitleAnimation
          )}
        >
          {title}
          {heroTitleSketch}
        </span>
        <span
          {...className(
            textStyles.heroNormalText,
            textStyles.sectionText,
            style.heroAnimation
          )}
        >
          {textAfterTitle}
        </span>
        <div
          {...className(
            style.heroWrapperChildrenContainer,
            style.heroAnimation
          )}
        >
          {children}
        </div>
      </div>
      <div {...className(style.iconContainer)}>
        <a href={arrowIconLink} {...className(style.arrowLink)}>
          <ArrowIcon {...className(style.icon)} />
        </a>
      </div>
    </div>
  );
}

export default HeroWrapper;
