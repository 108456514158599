.rotatingStarSketchMedium {
  width: 80px;
  height: 80px;
  top: 35px;
  right: -65px;
  background-color: var(--sketch-light);
  -webkit-mask-image: url("../../../assets/sketches/star-1.svg");
  mask-image: url("../../../assets/sketches/star-1.svg");
  pointer-events: none;
}

.rotatingStarSketchSmall {
  width: 50px;
  height: 50px;
  top: 95px;
  right: 15px;
  background-color: var(--sketch-light);
  -webkit-mask-image: url("../../../assets/sketches/star-2.svg");
  mask-image: url("../../../assets/sketches/star-2.svg");
  pointer-events: none;
}

.sparkleClusterSketch {
  width: 100px;
  height: 100px;
  top: -55px;
  right: -55px;
}

@media (max-width: 480px) {
  .sparkleClusterSketch {
    top: -60px;
    right: 0px;
  }
}
