.headerContainer {
  position: fixed;
  background-color: var(--primary-dark);
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 15px var(--primary-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.headerContent {
  width: 100%;
  max-width: var(--container-max-width);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-sm) var(--padding-md);
}

.navigationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigationLinksContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);
}

.headerButton {
  margin: 0 !important;
  font-size: 1.1rem !important;
}

.hamburgerButton {
  display: none;
}

.hamburgerIcon {
  display: none;
}

.mobileHeaderButton {
  display: none !important;
}

@media (hover: hover) {
  .hamburgerButton:hover {
    cursor: pointer;
  }
}

@media (max-width: 700px) {
  .hamburgerButton {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hamburgerIcon {
    display: flex;
    width: 30px;
    height: 30px;
    fill: var(--primary-light);
  }

  .navigationContainer {
    display: none;
  }

  .desktopHeaderButton {
    display: none !important;
  }

  .mobileHeaderButton {
    display: block !important;
  }

  .navigationLinksContainer {
    flex-direction: column;
  }

  .mobileNavigationContainer {
    border-top: 1px solid var(--primary-light);
    border-bottom: 1px solid var(--primary-light);
    background-color: var(--primary-dark);
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: var(--gap-md);
    top: 60px;
    left: 0;
    right: 0;
    padding: var(--padding-md);
  }
}
