.rotatingStarSketchMedium {
  width: 80px;
  height: 80px;
  top: -110px;
  right: 5px;
  background-color: var(--sketch-dark);
  -webkit-mask-image: url("../../../assets/sketches/star-1.svg");
  mask-image: url("../../../assets/sketches/star-1.svg");
}

.rotatingStarSketchSmall {
  width: 50px;
  height: 50px;
  top: -45px;
  right: -50px;
  background-color: var(--sketch-dark);
  -webkit-mask-image: url("../../../assets/sketches/star-2.svg");
  mask-image: url("../../../assets/sketches/star-2.svg");
}

@media (max-width: 700px) {
  .rotatingStarSketchMedium {
    top: -110px;
    right: -50px;
  }

  .rotatingStarSketchSmall {
    top: -45px;
    right: -80px;
  }
}

@media (max-width: 480px) {
  .rotatingStarSketchMedium {
    top: -110px;
    right: 45px;
  }

  .rotatingStarSketchSmall {
    top: -60px;
    right: -15px;
  }
}
