import React from "react";
import Header from "../../components/features/Header";
import ProjectMemery from "../../components/projects/ProjectMemery";
import ProjectNotelux from "../../components/projects/ProjectNotelux";
import ProjectMortle from "../../components/projects/ProjectMortle";
import Footer from "../../components/features/Footer";
import { projectsPageNavigationLinks } from "../../data/constants";
import HeroProjects from "../../components/sections/HeroProjects/HeroProjects";
import PageWrapper from "../../components/templates/PageWrapper";
import PrimaryButton from "../../components/buttons/PrimaryButton/PrimaryButton";
import * as style from "./ProjectsPage.module.css";

function ProjectsPage() {
  return (
    <PageWrapper>
      <Header navigationLinks={projectsPageNavigationLinks} />
      <HeroProjects />
      <ProjectNotelux />
      <ProjectMemery />
      <ProjectMortle />
      <PrimaryButton
        extraButtonStyle={style.backToHomePageButton}
        buttonLinkInternal="/"
        onButtonClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        Back to home page
      </PrimaryButton>
      <Footer />
    </PageWrapper>
  );
}

export default ProjectsPage;
