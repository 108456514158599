.footerContainer {
  background-color: var(--primary-dark);
  color: var(--primary-light);
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-md);
  padding: var(--padding-lg) var(--padding-md);
  padding-bottom: var(--padding-md);
  width: 100%;
  box-shadow: 0 -1px 15px var(--primary-dark);
}

.footerIconsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: var(--gap-md);
  max-width: 200px;
  width: 100%;
  max-height: 200px;
  height: 100%;
  margin-bottom: var(--margin-md);
}

.footerIcon {
  width: 30px;
  height: 30px;
  color: var(--primary-light);
  transition: all 0.2s ease-in-out;
}

@media (hover: hover) {
  .footerIcon:hover {
    color: var(--text-bg);
    transform: scale(1.1);
  }
}

@media (max-width: 480px) {
  .footerIconsContainer {
    flex-direction: column;
  }
}
