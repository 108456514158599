.skillsContainer {
  margin-top: var(--margin-xl);
  display: flex;
  width: 100%;
  gap: var(--gap-lg);
  justify-content: center;
  align-items: flex-start;
}

.imageContainer {
  max-height: 100%;
  max-width: 300px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutImage {
  width: 100%;
  height: auto;
}

.skillsTextContent {
  width: 50%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}

.highVoltageSketch {
  width: 100px;
  height: 100px;
  right: -70px;
  top: -80px;
  rotate: 40deg;
  background-color: var(--sketch-dark);
  -webkit-mask-image: url("../../../assets/sketches/high-voltage.svg");
  mask-image: url("../../../assets/sketches/high-voltage.svg");
}

.sparkleClusterSketch {
  width: 100px;
  height: 100px;
  top: -60px;
  left: -25px;
  rotate: -5deg;
  transform: rotateY(180deg);
}

.animatedRocketSketch {
  width: 150px;
  height: 150px;
  top: -100px;
  right: -175px;
  transform: rotateZ(30deg);
  background-color: var(--sketch-dark);
  -webkit-mask-image: url("../../../assets/sketches/rocket-1.svg");
  mask-image: url("../../../assets/sketches/rocket-1.svg");
  animation-name: animateRocket;
}

@keyframes animateRocket {
  0% {
    -webkit-mask-image: url("../../../assets/sketches/rocket-1.svg");
    mask-image: url("../../../assets/sketches/rocket-1.svg");
  }
  33% {
    -webkit-mask-image: url("../../../assets/sketches/rocket-2.svg");
    mask-image: url("../../../assets/sketches/rocket-2.svg");
  }
  66% {
    -webkit-mask-image: url("../../../assets/sketches/rocket-3.svg");
    mask-image: url("../../../assets/sketches/rocket-3.svg");
  }
  100% {
    -webkit-mask-image: url("../../../assets/sketches/rocket-1.svg");
    mask-image: url("../../../assets/sketches/rocket-1.svg");
  }
}

@media (max-width: 700px) {
  .skillsContainer {
    flex-direction: column;
    align-items: center;
  }

  .skillsTextContent {
    gap: var(--gap-md);
    width: 100%;
  }

  .imageContainer {
    width: 100%;
  }

  .sparkleClusterSketch {
    width: 70px;
    height: 70px;
  }

  .animatedRocketSketch {
    width: 100px;
    height: 100px;
    top: -50px;
    right: -120px;
  }
}
