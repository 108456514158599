import React, { useState } from "react";
import { className } from "../../../utilities/helpers";
import * as style from "./ProjectWrapper.module.css";
import * as textStyles from "../../../assets/styles/text-styles.module.css";
import * as shared from "../../../assets/styles/shared.module.css";
import PrimaryButton from "../../buttons/PrimaryButton/PrimaryButton";
import SectionWrapper from "../SectionWrapper/SectionWrapper";

function ProjectWrapper({
  projectName,
  children,
  mobileScreenshot,
  desktopScreenshots,
  githubLink,
  websiteLink,
  designLink,
  projectTitleSketch,
  projectMobileScreenshotSketch,
}) {
  const [selectedImage, setSelectedImage] = useState(desktopScreenshots[0]);

  return (
    <SectionWrapper
      sectionId={projectName}
      sectionTitle={projectName}
      sectionTitleSketch={projectTitleSketch}
    >
      <div {...className(style.projectSectionContainer)}>
        <div {...className(style.projectDetailsContainer)}>
          <div {...className(style.projectDetailsContent)}>
            <span {...className(textStyles.normalText)}>{children}</span>
            <div {...className(style.projectLinksContainer)}>
              {githubLink && (
                <PrimaryButton
                  extraButtonStyle={style.projectLink}
                  buttonLinkExternal={githubLink}
                >
                  Github
                </PrimaryButton>
              )}

              {websiteLink && (
                <PrimaryButton
                  extraButtonStyle={style.projectLink}
                  buttonLinkExternal={websiteLink}
                >
                  Website
                </PrimaryButton>
              )}
              {designLink && (
                <PrimaryButton
                  extraButtonStyle={style.projectLink}
                  buttonLinkExternal={websiteLink}
                >
                  Design
                </PrimaryButton>
              )}
            </div>
          </div>
          <div
            {...className(style.mobileScreenshotContainer, shared.sketchHelper)}
          >
            {projectMobileScreenshotSketch}
            <img
              {...className(
                style.mobileScreenshotContent,
                shared.frame,
                shared.frameRight
              )}
              src={mobileScreenshot}
              alt=""
            />
          </div>
        </div>
        <div {...className(style.desktopScreenshotContainer)}>
          <img
            {...className(
              style.desktopScreenshotContent,
              shared.frame,
              shared.frameLeft
            )}
            src={selectedImage}
            alt=""
          />
        </div>
        <div {...className(style.screenshotPreviewsContainer)}>
          {desktopScreenshots.map((screenshot) => {
            return (
              <button
                key={screenshot}
                onClick={() => setSelectedImage(screenshot)}
              >
                <img
                  alt=""
                  src={screenshot}
                  {...className(shared.frame, style.screenshotPreviewContent)}
                ></img>
              </button>
            );
          })}
        </div>
      </div>
    </SectionWrapper>
  );
}

export default ProjectWrapper;
