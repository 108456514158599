import React from "react";
import * as style from "./HeroMain.module.css";
import HeroWrapper from "../../templates/HeroWrapper/HeroWrapper";
import PrimaryButton from "../../buttons/PrimaryButton/PrimaryButton";
import SketchDecoration from "../../templates/SketchDecoration/SketchDecoration";
import AnimatedSparkleClusterSketch from "../../features/AnimatedSparkleClusterSketch";

function HeroMain() {
  return (
    <HeroWrapper
      textBeforeTitle="Hello, my name is"
      title="Anna Rudas"
      textAfterTitle="I am a software developer based in Germany."
      arrowIconLink="#about"
      heroTitleSketch={
        <AnimatedSparkleClusterSketch
          extraSparkleClusterStyle={style.sparkleClusterSketch}
        />
      }
    >
      <PrimaryButton
        buttonLinkInternal={"CV-annarudas.pdf"}
        isDarkButtonStyle={false}
        isButtonToDownload={true}
        primaryButtonSketch={
          <>
            <SketchDecoration
              sketchStyle={style.rotatingStarSketchMedium}
              rotating={true}
            />
            <SketchDecoration
              sketchStyle={style.rotatingStarSketchSmall}
              rotating={true}
            />
          </>
        }
      >
        Download my CV
      </PrimaryButton>
    </HeroWrapper>
  );
}

export default HeroMain;
