.heroWrapper * {
  z-index: 3;
}

.heroWrapper {
  background-image: url("../../../assets/images/background-hero.svg");
  background-repeat: repeat;
  color: var(--primary-light);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-lg);
  padding-top: var(--padding-xl);
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--primary-dark);
  min-height: 100vh;
  width: 100%;
  margin-bottom: var(--margin-lg);
}

@supports (-webkit-touch-callout: none) {
  .heroWrapper {
    min-height: var(--hero-height);
  }
}

.heroContent {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: var(--container-max-width);
}

.iconContainer {
  height: fit-content;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 2.5s;
  animation-name: arrowLoop;
}

@keyframes arrowLoop {
  0% {
    bottom: 25px;
  }
  50% {
    bottom: 50px;
  }
  100% {
    bottom: 25px;
  }
}

.icon {
  width: 40px;
  height: 40px;
  fill: var(--primary-light);
}

.heroWrapperChildrenContainer {
  height: fit-content;
  width: 100%;
}

.heroTitleAnimation {
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-name: heroAnimation;
}

.heroAnimation {
  opacity: 0%;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-name: heroAnimation;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes heroAnimation {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@media (hover: hover) {
  .arrowLink:hover {
    cursor: pointer;
  }
}

@media (min-width: 1100px) {
  .heroWrapper {
    gap: var(--gap-sm);
  }
}

@media (min-width: 1400px) {
  .icon {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 480px) {
  .heroWrapper {
    padding: var(--padding-lg) var(--padding-md);
  }
}
