import React from "react";
import { className } from "../../../utilities/helpers";
import * as style from "./ProjectCard.module.css";
import * as textStyles from "../../../assets/styles/text-styles.module.css";
import PrimaryButton from "../../buttons/PrimaryButton/PrimaryButton";
import InfoTags from "../InfoTags/InfoTags";
import OpenInNewTabIcon from "../../../assets/icons/OpenInNewTabIcon";

function ProjectCard({ name, title, intro, featureTags, projectImg, link }) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      {...className(style.projectCardContainer)}
    >
      <div {...className(style.projectName)}>
        <span {...className(style.name, textStyles.primaryButtonText)}>
          {name}
        </span>
      </div>
      <div {...className(style.projectBody)}>
        <div {...className(style.projectDescription)}>
          <span {...className(textStyles.subtitleText)}>{title}</span>
          <span {...className(textStyles.normalText)}>{intro}</span>
          <InfoTags tagList={featureTags} />
        </div>
        <div
          style={{ backgroundImage: "url(" + projectImg + ")" }}
          {...className(style.projectImage)}
        ></div>
      </div>
      <div {...className(style.buttonContainer)}>
        <PrimaryButton extraButtonStyle={style.button}>
          Go to website
        </PrimaryButton>
      </div>
      <div {...className(style.iconContainer)}>
        <OpenInNewTabIcon {...className(style.openInNewTabIcon)} />
      </div>
    </a>
  );
}

export default ProjectCard;
