import React from "react";
import ProjectWrapper from "../../templates/ProjectWrapper/ProjectWrapper";
import desktopScreenshot1 from "../../../assets/images/projects/memery-desktop-1.png";
import desktopScreenshot2 from "../../../assets/images/projects/memery-desktop-2.png";
import desktopScreenshot3 from "../../../assets/images/projects/memery-desktop-3.png";
import mobileScreenshot from "../../../assets/images/projects/memery-mobile.png";
import { projectLinkMemery, githubLinkMemery } from "../../../data/constants";
import SketchDecoration from "../../templates/SketchDecoration";
import * as style from "./ProjectMemery.module.css";
import * as shared from "../../../assets/styles/shared.module.css";

function ProjectMemery() {
  const desktopScreenshots = new Array(
    desktopScreenshot1,
    desktopScreenshot2,
    desktopScreenshot3
  );

  return (
    <ProjectWrapper
      projectName="memery"
      mobileScreenshot={mobileScreenshot}
      desktopScreenshots={desktopScreenshots}
      githubLink={githubLinkMemery}
      websiteLink={projectLinkMemery}
      projectTitleSketch={
        <SketchDecoration
          sketchStyle={` ${style.animatedAlienShipSketch} ${shared.sketchAnimation}`}
        />
      }
    >
      <>
        I have been an avid collector of different types of cat images and I
        have finally found a use for it: a memory game! I love using my
        developer skills to create fun games like this that I can share with my
        friends. <br />
        The game offers three different types of card pack sizes and types to
        choose from. Users can track their progress and how many turns it takes
        to complete the game.
        <br />
        The most challenging aspect of this project was the styling,
        specifically with the cards grid, to make sure the cards don&apos;t
        overflow and to maintain a responsive design. This project also made me
        learn more about css transformations and animations as these are what I
        used to rotate the cards and create the flipping effect.
      </>
    </ProjectWrapper>
  );
}

export default ProjectMemery;
