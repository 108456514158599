.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: var(--padding-lg) var(--padding-sm);
}

.formContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--content-max-width);
  min-height: 485px;
  height: 100%;
  gap: var(--gap-md);
  flex-direction: column;
  background-color: var(--primary-light);
  padding: var(--padding-md);
}

.senderInfoContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-md);
}

.formSubmitButton {
  margin: 0;
  border: 1px solid var(--primary-dark) !important;
  background-color: var(--primary-light) !important;
  color: var(--primary-dark) !important;
  box-shadow: 5px 5px 0px var(--primary-dark) !important;
  width: 50%;
  min-width: 150px;
  max-width: 230px;
  position: relative;
  min-height: 45px;
}

.animatedPaperAirplaneSketch {
  width: 200px;
  height: 200px;
  top: -40px;
  right: -240px;
  background-color: var(--sketch-dark);
  animation-name: animatePaperAirplane;
  -webkit-mask-image: url("../../../assets/sketches/paper-airplane-1.svg");
  mask-image: url("../../../assets/sketches/paper-airplane-1.svg");
}

@keyframes animatePaperAirplane {
  0% {
    -webkit-mask-image: url("../../../assets/sketches/paper-airplane-1.svg");
    mask-image: url("../../../assets/sketches/paper-airplane-1.svg");
  }
  33% {
    -webkit-mask-image: url("../../../assets/sketches/paper-airplane-2.svg");
    mask-image: url("../../../assets/sketches/paper-airplane-2.svg");
  }
  66% {
    -webkit-mask-image: url("../../../assets/sketches/paper-airplane-3.svg");
    mask-image: url("../../../assets/sketches/paper-airplane-3.svg");
  }
  100% {
    -webkit-mask-image: url("../../../assets/sketches/paper-airplane-1.svg");
    mask-image: url("../../../assets/sketches/paper-airplane-1.svg");
  }
}

.sparkleClusterSketch {
  width: 100px;
  height: 100px;
  top: -60px;
  left: -25px;
  rotate: -5deg;
  transform: rotateY(180deg);
}

@media (hover: hover) {
  .formSubmitButton:hover {
    background-color: var(--primary-dark) !important;
    color: var(--primary-light) !important;
    box-shadow: none !important;
  }
  .formSubmitButton:focus-visible {
    background-color: var(--primary-dark) !important;
    color: var(--primary-light) !important;
    box-shadow: none !important;
  }
}

@media (max-width: 700px) {
  .senderInfoContainer {
    flex-direction: column;
  }

  .animatedPaperAirplaneSketch {
    width: 150px;
    height: 150px;
    top: -105px;
    right: -130px;
  }

  .sparkleClusterSketch {
    width: 70px;
    height: 70px;
  }
}
