.button {
  border-radius: var(--border-radius);
  padding: var(--padding-xs) var(--padding-sm);
  transition: all 0.3s ease-in-out;
  margin: var(--margin-md) 0;
  width: fit-content;
  height: fit-content;
  display: block;
}

.button:disabled {
  pointer-events: none !important;
}

.buttonLight {
  border: 2px solid var(--primary-light);
  background-color: var(--primary-light);
  color: var(--primary-dark);
}

.buttonDark {
  border: 2px solid var(--primary-dark);
  background-color: var(--primary-dark);
  color: var(--primary-light);
}

@media (hover: hover) {
  .button:hover {
    cursor: pointer;
  }
  .buttonLight:hover {
    background-color: var(--primary-dark);
    color: var(--primary-light);
    box-shadow: 5px 5px 0px var(--primary-light);
  }

  .buttonLight:focus-visible {
    background-color: var(--primary-dark);
    color: var(--primary-light);
    box-shadow: 5px 5px 0px var(--primary-light);
  }

  .buttonDark:hover {
    background-color: var(--primary-light);
    color: var(--primary-dark);
    box-shadow: 5px 5px 0px var(--primary-dark);
  }

  .buttonDark:focus-visible {
    background-color: var(--primary-light);
    color: var(--primary-dark);
    box-shadow: 5px 5px 0px var(--primary-dark);
  }
}
