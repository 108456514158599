import React from "react";
import { className } from "../../../utilities/helpers";
import * as style from "./SectionWrapper.module.css";
import * as textStyles from "../../../assets/styles/text-styles.module.css";
import * as shared from "../../../assets/styles/shared.module.css";

function SectionWrapper({
  children,
  sectionId,
  sectionTitle,
  extraSectionWrapperStyle,
  sectionTitleSketch,
}) {
  return (
    <div
      {...className(style.sectionWrapper, extraSectionWrapperStyle)}
      id={sectionId}
    >
      <span {...className(textStyles.titleText, shared.sketchHelper)}>
        {sectionTitle}
        {sectionTitleSketch}
      </span>
      {children}
    </div>
  );
}

export default SectionWrapper;
